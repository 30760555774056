import Intercom from '@intercom/messenger-js-sdk';
import type { User } from 'node_modules/@shopify/shopify-api/dist/ts/rest/admin/2024-10/user';
import { useEffect } from 'react';

export default function IntercomWidget({ shop, user, plan, intercomUserHash, staffCount }: { shop: string | undefined, user: User | null | undefined, plan: string | undefined, intercomUserHash: string | undefined, staffCount: number | undefined }) {
  useEffect(() => {
    if (shop && user?.id && user?.email) {
      const shopName = shop.replace(/\.myshopify\.com$/, '')
      Intercom({
        app_id: 'jpl8dqlt',
        user_id: user.id.toString(),
        user_hash: intercomUserHash,
        name: user.first_name ?? undefined,
        email: user.email,
        company: {
          company_id: shopName,
          name: shopName,
          plan: plan,
          website: shop,
          size: staffCount
        }
      });
    } else {
      Intercom({
        app_id: 'jpl8dqlt',
      });
    }
  })

  return (<></>)
}